import About from './About/About';
import './App.css';
import Calculator from './Calculator/Calculator';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import OurServices from './Our services/Our-services';
import Portfolio from './Portfolio/Portfolio';
import Process from './Process/Process';
import SmartHome from './SmartHome/SmartHome';

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Portfolio />
      <SmartHome />
      <Process />
      <OurServices />
      <Calculator />
      <Footer />
    </div>
  );
}

export default App;
