import React from 'react';
import './Portfolio.css';

const Portfolio = () => {

  return (
    <section id="portfolio" className="portfolio">
      <div className="portfolio_grid">
        <div className="portfolio_slide portfolio_slide--big"></div>


        <div className="portfolio_wrapper">
          <div className="portfolio_slide portfolio_slide--small"></div>

          <div className="portfolio_count_wrapper">
            <div className="portfolio_count">
              <span className="portfolio_count_digit">10K+</span>
              <p className="portfolio_count_text">Довольных клиентов</p>
            </div>

            <div className="portfolio_count">
              <span className="portfolio_count_digit">50+</span>
              <p className="portfolio_count_text">Завершенных проектов</p>
            </div>
          </div>
        </div>

        <div className="portfolio_folder_wrapper">
          <h3 className="portfolio_header">Наши завершенные
            проекты</h3>
          <p className="portfolio_text">
            Компания TechGlobal Industries с гордостью сообщает об успешном завершении более 50 проектов по электромонтажу зданий. Все клиенты остались довольны качеством выполненных работ и уровнем профессионализма наших специалистов.
          </p>
          <p className="portfolio_text">
            Мы гордимся тем, что смогли предоставить нашим клиентам надёжные и качественные услуги, соответствующие самым высоким стандартам. Наша команда опытных электриков и инженеров всегда стремится к тому, чтобы обеспечить максимальную безопасность и эффективность работы систем электроснабжения.
          </p>
          <p className="portfolio_text">
            В процессе выполнения проектов мы учитываем индивидуальные особенности каждого объекта, а также требования и пожелания заказчиков. Благодаря этому наши решения являются уникальными и адаптированными под конкретные нужды клиента.
          </p>
          <a href="#" className="portfolio_link">Узнать подробнее</a>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
