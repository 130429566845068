import React from 'react';
import './Header.css';
import Logo from '../Logo/Logo';

function Header() {
  return (
    <header id="header" className="header">
      <div className="header_logo">
        <Logo />
      </div>
        <ul className="nav_list">
          <a className="nav_link" href="#about">О нас</a>
          <a className="nav_link" href="#portfolio">Наши проекты</a>
          <a className="nav_link" href="#our-services">Услуги</a>
          <a className="nav_link" href="#calculator">Цены</a>
        </ul>
    </header>
  );
}

export default Header;
