import React from 'react';
import './Footer.css';
import Logo from '../Logo/Logo';
import { FACEBOOK, INSTAGRAM } from '../constants';

function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer_wrapper">
        <div className="footer_left">
          <div className="footer_logo">
            <Logo />
          </div>
          <ul className="footer_links">
            <a className="footer_link" href="#about">О нас</a>
            <a className="footer_link" href="#portfolio">Наши проекты</a>
            <a className="footer_link" href="#our-services">Услуги</a>
            <a className="footer_link" href="#calculator">Цены</a>
          </ul>

          <div className="footer_social">
            <a href={FACEBOOK} target='_blank'>
              <img src="/assets/svg/icon-fb.svg" alt="" />
            </a>
            <a href={INSTAGRAM} target='_blank'>
              <img src="/assets/svg/icon-instagram.svg" alt="" />
            </a>
          </div>
        </div>
        <p className="footer_copyright">© Copyright 2024, All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
